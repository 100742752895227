<template>
  <div class="l-wrapper login">
    <AppHeader />
    <main class="l-main -login">
      <div class="l-contents-white l-box">
        <div class="l-contents-white__logo">
          <h1>
            <img src="/v_resources/assets/img/common/logo01.svg" alt="CBA" />
          </h1>
        </div>
        <form>
          <ErrorMessage :errMsgs="errMsgs" />
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>メールアドレス</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                :value.sync="form.mail"
                tooltip="メールアドレスは必須入力です。"
                inputType="email"
                placeholder="メールアドレス"
                :validation="validateBlank"
                :isValid.sync="isValidOf.mail"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>パスワード</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                <InputPassword
                  :value.sync="form.password"
                  tooltip="パスワードは必須入力です。"
                  :validation="validateBlank"
                  :isValid.sync="isValidOf.password"
                />
                <router-link to="/reminder/input" class="c-text link u-block">
                  パスワードをお忘れの方
                </router-link>
              </div>
            </div>
          </div>
          <div class="c-inputWrap l-block-lg">
            <div class="c-inputWrap__label"></div>
            <div class="c-inputWrap__items">
              <a
                href="/v_resources/assets/pdf/問い合わせ窓口.pdf"
                target="_blank"
                class="c-text question"
              >
                ログインにお困りの方
              </a>
            </div>
          </div>
          <div>
            <div class="c-btn primary mid center l-half-box" @click="submit">
              送信
            </div>
            <div class="c-checkbox">
              <input
                id="save-login-confirm"
                type="checkbox"
                v-model="form.rememberMe"
              />
              <label
                class="c-checkbox__label u-center"
                for="save-login-confirm"
              >
                <span class="c-checkbox__box"></span>
                ログイン状態を保存する
              </label>
            </div>
          </div>
        </form>
      </div>
      <!-- /.l-contents-white -->
    </main>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import InputPassword from "@/components/parts/input/InputPassword";
import validation from "@/mixin/validation";
import { API_URL } from "@/const";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "login",
  components: {
    InputText,
    InputPassword,
  },
  mixins: [validation, selectionLocalStorage],
  data() {
    return {
      // 仮value
      form: {
        mail: "",
        password: "",
        rememberMe: false,
      },
      errMsgs: [],
      isValidOf: {
        mail: false,
        password: false,
      },
    };
  },
  methods: {
    postLoginApi() {
      axios
        .post(API_URL.AUTH.LOGIN, this.form)
        .then((res) => {
          if (res.status === 200) {
            this.$route.query.redirect ?
                this.$router.push(decodeURIComponent(this.$route.query.redirect))
                : this.$router.push("/home");
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            if (err.response.data.code === "loginExpired") {
              const memberId = err.response.data.memberId, onetimeToken = err.response.data.onetimeToken;
              this.$router.push(`/reminder/${memberId}/input?token=${onetimeToken}`);
            }
          } else {
            this.errMsgs.push(err.message);
          }
        });
    },
    async submit() {
      this.errMsgs = [];
      if (!this.isValidOf.mail) {
        this.errMsgs.push("メールアドレスは必須入力です。");
      }
      if (!this.isValidOf.password) {
        this.errMsgs.push("パスワードは必須入力です。");
      }
      if (this.isValidOf.mail && this.isValidOf.password) {
        await this.postLoginApi();
      }
    },
  },
  mounted() {
    // public系の画面はログアウトさせる
    this.$store.commit("auth/set", false);
    this.$store.commit("userInfo/set", {});

    //事業者情報のキャッシュをクリアする
    this.removeAllSelectionLocalStorage();
  },
};
</script>
